<template>
    <v-card>
        <v-card-text>
            <div class="text-subtitle-1 d-flex justify-space-between">
                <div class="text-subtitle-1">
                    Ocupación de {{moment(estaFechaOcupacion, 'DD-MM-YYYY').format('dddd MMMM D, YYYY')}}
                </div>
                    <page-fecha
                        :fecha.sync="estaFechaOcupacion"
                        :futuro="false"
                    />
            </div>

            <apexchart
                type='bar'
                :options="options"
                :series="series"
            />


        </v-card-text>
        <dialog-movs
            :movimientos='movimientos'
            :dialogMovs.sync='dialogMovs'
        />
    </v-card>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
    mounted(){
        this.cargaDatos()
    },
    components:{
        'apexchart':VueApexCharts,
        'page-fecha':()=>import('@/components/com/PageFecha'),
        'dialog-movs':()=>import('@/components/dashboard/ocupacion/OcupacionDetalles'),
    },
    data:()=>({
        estaFechaOcupacion:"",
        servicios:[
            'Urg',
            'UCIP',
            'UCIN-1',
            'Infe',
            'Mint',
            'Onco',
            'Cir',
            'Cir Amb',
            'COVID',
            'Q Ambu',
            'UCIN-2',
            'UCIN C y D',
        ],
        movimientosTodos:[],
        options:{},
        series:[],
        datosTodos:{},

        dialogMovs:false,
        movimientos:[],
    }),
    methods:{
        async cargaDatos(){

            try{
                let req = await Promise.all([
                    this.$http.get('/dashboard/listOcupacion',{params:{fecha:this.estaFechaOcupacion, areaMovimiento:1}}),
                    this.$http.get('/dashboard/listOcupacion',{params:{fecha:this.estaFechaOcupacion, areaMovimiento:2}}),
                    this.$http.get('/dashboard/listOcupacion',{params:{fecha:this.estaFechaOcupacion, areaMovimiento:3}}),
                    this.$http.get('/dashboard/listOcupacion',{params:{fecha:this.estaFechaOcupacion, areaMovimiento:5}}),
                    this.$http.get('/dashboard/listOcupacion',{params:{fecha:this.estaFechaOcupacion, areaMovimiento:6}}),
                    this.$http.get('/dashboard/listOcupacion',{params:{fecha:this.estaFechaOcupacion, areaMovimiento:7}}),
                    this.$http.get('/dashboard/listOcupacion',{params:{fecha:this.estaFechaOcupacion, areaMovimiento:8}}),
                    this.$http.get('/dashboard/listOcupacion',{params:{fecha:this.estaFechaOcupacion, areaMovimiento:9}}),
                    this.$http.get('/dashboard/listOcupacion',{params:{fecha:this.estaFechaOcupacion, areaMovimiento:10}}),
                    this.$http.get('/dashboard/listOcupacion',{params:{fecha:this.estaFechaOcupacion, areaMovimiento:11}}),
                    this.$http.get('/dashboard/listOcupacion',{params:{fecha:this.estaFechaOcupacion, areaMovimiento:13}}),
                    this.$http.get('/dashboard/listOcupacion',{params:{fecha:this.estaFechaOcupacion, areaMovimiento:14}}),
                ])
                this.movimientosTodos = req
                let total = []
                req.forEach(ele => {
                    //console.log(ele.data.root)
                    //let camas = ele.data.root[0].camas
                    //console.log (camas)
                    let ocupa = new Set(ele.data.root.map(a=>a.cip)).size
                    if (ocupa > 0){
                        total.push(ocupa)
                        //console.log(ocupa+' de '+ele.data.root[0].camas)
                    } else {
                        total.push(0)
                    }


                })
                /*
                let req = await this.$http({
                    method: 'GET',
                    url:'/dashboard/listOcupacion',
                    params:{fecha:this.estaFechaOcupacion}
                })
                */
                this.options={
                    chart:{
                        events:{
                            dataPointSelection: (event, chartContext, config) => {
                                //console.log(config.dataPointIndex)
                                //console.log(this.movimientosTodos[config.dataPointIndex].data)
                                //this.movimientos = this.movimientosTodos[config.dataPointIndex].data.root
                                this.movimientos = [...new Map(this.movimientosTodos[config.dataPointIndex].data.root.map((item) => [item.cip, item])).values()]
                                this.dialogMovs = true
                            }
                        },
                    },
                    tooltip: {
                        enabled:false,
                    },
                    xaxis: {
                        categories: this.servicios,
                    },
                    yaxis:{
                        min: 0,
                        max: 20,
                        decimalsInFloat: 0,
                    },
                    dataLabels: {
                        formatter(val){
                            return(val)
                        },
                    },
                }

                this.series=[{
                    name: "Ocupa",
                    data:total
                }]

            } catch(err) {
                this.$store.dispatch('security/revisaError',err)
            }
        },
    },
    watch:{
        estaFechaOcupacion(){
            this.cargaDatos()
        },
    },

}
</script>

<style>

</style>